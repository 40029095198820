<template>
  <div>
    <Toolbar />
    <Dashboard />
    <Footer />
  </div>
</template>

<script>
import Dashboard from '@/components/ui/layout/Dashboard.page.vue';
import Toolbar from '@/components/ui/landing/toolbar/Toolbar.home.vue';
import Footer from '@/components/ui/landing/footer/Footer';
export default {
  components: {
    Footer,
    Dashboard,
    Toolbar,
  },
};
</script>
