<template>
  <v-sheet class="transparent">
    <v-container class="py-5">
      <router-view></router-view>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs';
import menu from './menu';

export default {
  props: {
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu,
      config,
      drawer: null,
      showSearch: false,
      isContentBoxed: false,
    };
  },
};
</script>

<style lang="scss">
.app-bar-full {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0;
  }
}
</style>
