<template>
  <v-sheet class="transparent">
    <Layout />
  </v-sheet>
</template>

<script>
import Layout from '@/components/ui/layout/Dashboard.body.vue';

export default {
  components: {
    Layout,
  },
};
</script>
