
















































export default {
  name: 'Devices',
  data() {
    return {
      carousel: 0,
      testimonials: [
        {
          name: 'Steven Molloy',
          image: '@/assets/images/Steve-M.jpeg',
          title: 'Director',
          testimonial: 'Steve Molloy has the following Australian professional designations:',
        },
        {
          name: 'Suki Molloy',
          title: 'Senior Associate',
          image: '/images/team/3.jpeg',
          testimonial:
            'Suki is a Fellow of the UK’s Association of Chartered Certified Accountants, being admitted in 1990.',
        },
      ],
    };
  },
};
