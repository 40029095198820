export default [
  {
    text: '',
    items: [
      {
        icon: 'mdi-view-dashboard-outline',
        key: 'menu.dashboard',
        text: 'Dashboard',
        link: '/',
      },
      {
        icon: 'mdi-account',
        key: 'menu.account',
        text: 'Profile',
        link: '/profile',
      },
      {
        icon: 'mdi-cube-scan',
        key: 'menu.dicom',
        text: '3Dicom Viewer',
        regex: /\b(subscriptions|devices|pricing)\b/i,
        items: [
          {
            icon: 'mdi-playlist-check',
            text: 'Pricing',
            link: '/pricing',
          },
          {
            icon: 'mdi-playlist-check',
            text: 'Subscriptions',
            link: '/subscriptions',
          },
          {
            icon: 'mdi-tablet-cellphone',
            text: 'Devices',
            link: '/devices',
          },
        ],
      },
    ],
  },
];
