export const menu = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Our Firm',
    link: '/firm',
  },
  {
    title: 'About Us',
    link: '/about',
  },
  {
    title: 'Compliance',
    link: '/compliance',
  },
  {
    title: 'Consulting',
    link: '/consulting',
  },
  {
    title: 'Pricing',
    link: '/pricing',
  },
  {
    title: 'Links',
    link: '/links',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];

export default {
  menu,
};
