




































































export default {
  name: 'Devices',
};
