<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn class="d-md-none drawer-button" rounded @click="drawer = !drawer">
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar flat height="80" color="transparent">
      <v-container class="py-0 px-0 px-sm-2 fill-height">
        <router-link to="/" class="d-flex align-center text-decoration-none mr-2 toolbar3-min-width">
          <img
            src="@/assets/images/cts.png"
            alt=""
            height="130"
            class="mr-0"
            style="position: absolute; top: 0; margin-left: 20px"
          />
        </router-link>

        <v-spacer></v-spacer>

        <div class="d-none d-md-block">
          <v-btn
            v-for="(item, index) in menu"
            :key="index"
            :to="item.link"
            text
            class="mx-1 font-weight-medium"
            v-html="item.title"
          >
          </v-btn>
        </div>

        <v-spacer></v-spacer>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { menu } from './menus.js';

export default {
  data() {
    return {
      drawer: null,
      menu,
    };
  },
};
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 64px;
  left: -22px;
  z-index: 6;
}

.toolbar3-min-width {
  min-width: 130px;
}
</style>
