<template>
  <v-footer color="transparent">
    <v-container class="py-5">
      <v-row>
        <v-col cols="12" md="7">
          <div class="text-h6 text-lg-h5 font-weight-bold">Contact Information</div>
          <div style="width: 80px; height: 4px" class="mb-5 mt-1 secondary" />
          <div class="d-flex mb-2 font-weight-bold">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-map-marker-outline</v-icon>
            <a
              href="https://goo.gl/maps/VnYJfNaPG2VgMQdy8"
              target="_blank"
              class="text-decoration-none secondary--text text--lighten-2"
              >23 St Helier Drive SORRENTO, WA. 6020 Australia</a
            >
          </div>
          <div class="d-flex mb-2">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-phone-outline</v-icon>
            <a href="tel:+61892469536" target="_blank" class="text-decoration-none secondary--text text--lighten-2"
              >+61 8 9246 9536</a
            >
          </div>

          <div class="d-flex mb-2">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-email-outline</v-icon>
            <a
              href="mailto:info@corptaxsolutions.com.au"
              target="_blank"
              class="text-decoration-none secondary--text text--lighten-2"
              >info@corptaxsolutions.com.au</a
            >
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="text-h6 text-lg-h5 font-weight-bold">CTS</div>
          <div style="width: 80px; height: 4px; font-weight: bold" class="mb-5 mt-1 secondary" />
          <strong>CORPTAX SOLUTIONS Pty Ltd </strong><br />Chartered Accountants & Registered Tax Agents
          <div class="text-center text-md-right mt-4 mt-lg-2">
            <img src="@/assets/images/tpb.png" alt=""  width="80" class="mr-4" />
            <img src="@/assets/images/tti.png" alt=""  width="120" class="ml-2 mr-2 mb-2" />
            <img src="@/assets/images/caccountants.png" alt="" width="180"  class="mr-0" />
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <div class="text-center caption">
        <Copyright />
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import Copyright from '@/components/ui/static/Copyright';
export default {
  components: { Copyright },
  data() {
    return {
      links: [
        {
          label: 'Overview',
          to: '#',
        },
        {
          label: 'Features',
          to: '#',
        },
        {
          label: 'Pricing',
          to: '#',
        },
        {
          label: 'Documentation',
          to: '#',
        },
        {
          label: 'News',
          to: '#',
        },
        {
          label: 'FAQ',
          to: '#',
        },
        {
          label: 'About us',
          to: '#',
        },
        {
          label: 'Carrers',
          to: '#',
        },
        {
          label: 'Press',
          to: '#',
        },
      ],
    };
  },
};
</script>
