export const links = [
  [
    {
      title: 'Tax Resources',
      links: [
        {
          title: 'Australian Taxation Office',
          link: 'http://www.ato.gov.au/'
        },
        {
          title: 'Check an ABN',
          link: 'http://www.abr.business.gov.au/'
        },
        {
          title: 'Tax Rulings',
          link: 'http://law.ato.gov.au/atolaw/findrul.htm'
        },
        {
          title: 'Tax Legislation',
          link: 'http://law.ato.gov.au/atolaw/findleg.htm'
        },
        {
          title: 'NSW State Taxes',
          link: 'http://www.osr.nsw.gov.au/'
        },
        {
          title: 'Victorian State Taxes',
          link: 'http://www.sro.vic.gov.au/'
        },
        {
          title: 'Queensland State Taxes',
          link: 'http://www.osr.qld.gov.au/'
        },
        {
          title: 'SA State Taxes',
          link: 'http://www.revenuesa.sa.gov.au/index.html'
        },
        {
          title: 'WA State Taxes',
          link: 'http://www.srd.wa.gov.au/'
        },
        {
          title: 'ACT State Taxes',
          link: 'http://www.revenue.act.gov.au/'
        },
        {
          title: 'NT State Taxes',
          link: 'http://www.revenue.act.gov.au/'
        },
        {
          title: 'Tasmainian State Taxes',
          link: 'http://www.treasury.tas.gov.au/domino/dtf/dtf.nsf'
        },
        {
          title: 'Guide To Australian Government Payments Booklet',
          link: 'http://www.centrelink.gov.au/internet/internet.nsf/publications/co029.htm'
        },
        {
          title: '2008/09 Tax Agent Lodgement Program',
          link: 'http://www.ato.gov.au/content/downloads/TPL00149935LPdeskcal.pdf'
        },
        {
          title: 'Individual Income Tax Rates',
          link: 'http://www.ato.gov.au/individuals/content.asp?doc=/content/12333.htm'
        },
        {
          title: 'Company income tax return instructions 2008',
          link: 'http://www.ato.gov.au/taxprofessionals/content.asp'
        },
        {
          title: 'Pocket Guide to the Australian Tax System',
          link: 'http://www.treasury.gov.au/contentitem.asp?NavId=&amp;ContentID=866'
        },

      ]
    },
    {
      title: 'Finance Links',
      links: [
        {
          title: 'Check out interest rates',
          link: 'http://www.infochoice.com.au/banking/'
        },
        {
          title: 'FX Rates',
          link: 'http://www.xe.net/'
        }
      ]
    },
    {
      title: 'Accounting Software Links',
      links: [
        {
          title: 'MYOB',
          link: 'http://www.myob.com.au/'
        },
        {
          title: 'Sage line 50',
          link: 'http://www.au.sage.com/'
        },
        {
          title: 'Arrow',
          link: 'http://www.arrow.net.au/'
        },
        {
          title: 'Quicken',
          link: 'http://software.quicken.com.au/'
        },
        {
          title: 'Tradesman',
          link: 'http://www.lawsonbanner.com/'
        }
      ]
    }

  ],
  [
    {
      title: 'Business Links',
      links: [
        {
          title: 'Australian Financial Review',
          link: 'http://www.afr.com.au/'
        },
        {
          title: 'Stockmarket',
          link: 'http://www.asx.com.au/'
        },
        {
          title: 'Check a Company Business Name',
          link: 'http://www.search.asic.gov.au/gns001.html'
        },
        {
          title: 'CPI Rates and Inflation',
          link: 'http://www.propertyweb.com.au/research/stats.cpi.html'
        },
        {
          title: 'Phonebook',
          link: 'http://www.whitepages.com.au/'
        },
        {
          title: 'Finance News',
          link: 'http://finance.news.com.au/'
        },
        {
          title: 'Domain Name Registration',
          link: 'http://www.inww.com/'
        },
      ]
    },
    {
      title: 'Anti-Virus Software Links',
      links: [
        {
          title: 'Symantec',
          link: 'http://www.symantec.com/avcenter/download.html'
        },
        {
          title: 'Vet',
          link: 'http://www.vet.com.au/'
        },
        {
          title: 'McAfee',
          link: 'http://software.mcafee.com/centers/download/'
        },
      ]
    }
  ],
  [
    {
      title: 'Other Links',
      links: [
        {
          title: 'CPA Australia',
          link: 'http://www.cpaonline.com.au/'
        },
        {
          title: 'Institute of Chartered Accountants',
          link: 'http://www.icaa.org.au/'
        },
        {
          title: 'ASIC',
          link: 'http://www.asic.gov.au/'
        },
        {
          title: 'AusIndustry',
          link: 'http://www.ausindustry.gov.au/'
        },
        {
          title: 'Australian Accounting Standards Board',
          link: 'http://www.aasb.com.au/Home.aspx'
        },
        {
          title: 'Taxation Institute of Australia',
          link: 'http://www.taxinstitute.com.au/'
        },
      ]
    }
  ]
]
