











































import {links} from "@/pages/Dashboard/links";

export default {
  name: 'Devices',
  data() {
    return {
      links
    };
  },
};
